<template>
  <div class="Home">
    <page-header/>
    <section class="uk-section uk-padding-remove">
      <div class="uk-container">
        <div class="uk-text-center uk-margin">
          <ul class="uk-breadcrumb uk-flex-center uk-margin-remove">
            <li>
              <router-link :to="{name: 'OrganizationExamRegister', params: { examId: 0}}">
                <span>{{ $t("orgaExam.questionManager.eReg") }}</span>
              </router-link>
            </li>
            <li>
              <span>{{ $t("orgaExam.questionManager.eManagement") }}</span>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section class="uk-container uk-padding-small uk-margin" id="scrollUp">
      <div class="uk-text-left uk-margin-bottom">
        <h4 class="qList">{{ $t("orgaExam.results.eName") }} : {{ examDetail.examName }}</h4>
      </div>
      <ul class="uk-subnav uk-subnav-pill ultypes">
        <template v-for="(item, index) in types">
          <li :class="{ 'checked': index == activeOption }" :key="index">
            <a href="javascript:void(0);" @click="selectType(index)"
               class="uk-button uk-button-primary uk-button-small uk-padding-small" style="width: 100px">
              {{ item.type }} - {{ $t("orgaExam.questionManager.variant") }}</a>
          </li>
        </template>
        <li>
          <button type="button" class="uk-button uk-button-success  uk-button-small uk-margin-left add-type"
                  style="width: 140px"
                  @click="addType"><i
              uk-icon="icon: plus-circle; ratio:0.8"></i> {{ $t("orgaExam.questionManager.aVariant") }}
          </button>
        </li>
        <li>
          <button type="button" class="uk-button uk-button-danger uk-button-small uk-margin-left remove-type"
                  @click="removeType">
            <i uk-icon="icon: trash; ratio:0.8"></i> {{ $t("orgaExam.questionManager.delete") }}
          </button>
        </li>
      </ul>
      <ul class="uk-margin">
        <template>
          <li>
            <ul class="uk-margin">
              <li v-show="gType == 'manual'">
                <div class="uk-card uk-card-default uk-width-1-1 uk-margin-top-small">
                  <table
                      class="uk-table uk-table-responsive uk-table-striped uk-table-small uk-remove-margin-bottom search-table">
                    <tbody>
                    <tr>
                      <td class="tit uk-text-bold">{{ $t("orgaExam.questionManager.qCate") }}</td>
                      <td colspan="3">
                        <div class="uk-flex" uk-grid>
                          <v-select @input="categoryChange('exam', 'main')"
                                    :placeholder="$t('orgaExam.questionManager.main')"
                                    v-model="search.cate1" label="name" :reduce="(option) => option.id"
                                    :options="mainCategory.main" class="uk-width-1-3 ">
                          </v-select>
                          <v-select @input="categoryChange('exam', 'middle')"
                                    v-model="search.cate2" label="name" :reduce="(option) => option.id"
                                    :placeholder="$t('orgaExam.questionManager.mid')"
                                    :options="mainCategory.middle" class="uk-width-1-3">
                          </v-select>
                          <v-select @input="categoryChange('exam', 'low')"
                                    v-model="search.cate3" label="name" :reduce="(option) => option.id"
                                    :placeholder="$t('orgaExam.questionManager.low')"
                                    :options="mainCategory.low" class="uk-width-1-3">
                          </v-select>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="tit">
                        <span class="input_require">{{ $t("orgaExam.questionManager.qType") }}</span>
                      </td>
                      <td colspan="3">
                        <input class="uk-radio uk-margin-small-left" name="qType" v-model="search.qtype"
                               type="radio" value="1"/>
                        <span class="uk-margin-small-left">{{ $t("orgaExam.questionManager.sChoice") }}</span>
                        <input
                            class="uk-radio uk-margin-left" name="qType" v-model="search.qtype"
                            type="radio"
                            value="3"
                        /> <span class="uk-margin-small-left">{{ $t("orgaExam.questionManager.mChoice") }}</span>
                        <input
                            class="uk-radio uk-margin-left" name="qType" v-model="search.qtype"
                            type="radio"
                            value="2"
                        /> <span class="uk-margin-small-left">{{ $t("orgaExam.questionManager.sAns") }}</span>
                        <input
                            class="uk-radio uk-margin-left" name="qType" v-model="search.qtype"
                            type="radio"
                            value="5"
                        /> <span class="uk-margin-small-left">{{ $t("organization.questionList.imageUpload") }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="tit uk-text-bold">{{ $t("orgaExam.questionManager.qLevel") }}</td>
                      <td>
                        <input
                            name="difficultyLevel"
                            type="radio"
                            value="1"
                            class="uk-radio uk-margin-small-left"
                            v-model="search.qlevel"
                        /> <span
                          class="uk-margin-small-left"> {{ $t("organization.questionList.low3") }}</span>
                        <input
                            name="difficultyLevel"
                            type="radio"
                            value="2"
                            class="uk-radio uk-margin-left"
                            v-model="search.qlevel"
                        /> <span
                          class="uk-margin-small-left"> {{ $t("organization.questionList.low2") }}</span>
                        <input
                            name="difficultyLevel"
                            type="radio"
                            value="3"
                            class="uk-radio uk-margin-left"
                            v-model="search.qlevel"
                        /> <span class="uk-margin-small-left"> {{ $t("organization.questionList.medium") }}</span>
                        <input
                            name="difficultyLevel"
                            type="radio"
                            value="4"
                            class="uk-radio uk-margin-left"
                            v-model="search.qlevel"
                        /> <span
                          class="uk-margin-small-left"> {{ $t("organization.questionList.high") }}</span>
                        <input
                            name="difficultyLevel"
                            type="radio"
                            value="5"
                            class="uk-radio uk-margin-left"
                            v-model="search.qlevel"
                        /> <span
                          class="uk-margin-small-left"> {{ $t("organization.questionList.high2") }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="tit">{{ $t("orgaExam.questionManager.key") }}</td>
                      <td colspan="3">
                        <input type="text" class="uk-input uk-form-small uk-margin-small-left"
                               style="width: 90%" placeholder="keyword"
                               v-model="search.keyword"/>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="uk-text-right uk-margin uk-margin-bottom">
                  <button type="button" @click="defaultSetting"
                          class="uk-button uk-button-secondary uk-button-small">
                    {{ $t("dSearch") }}
                  </button>
                  <button type="button" @click="questionData"
                          class="uk-button uk-button-primary uk-button-small uk-margin-left"><span
                      uk-icon="icon:search;ratio: 0.8;"></span> {{ $t("orgaExam.questionManager.search") }}
                  </button>
                </div>
                <div class="uk-width-1-1@m uk-margin-remove-left" uk-grid>
                      <div class="uk-width-expand@m uk-width-1-1 uk-margin-remove-left uk-margin-top uk-padding-remove">
                        <p class="modify_sbtn" align="left">
                          <a href="javascript:void(0);" @click="selectAll('left', true)">
                            <span class="msbtn2">{{ $t("orgaExam.questionManager.sAll") }}</span></a>
                          <a href="javascript:void(0);" @click="selectAll('left', false)">
                            <span class="msbtn2">{{ $t("orgaExam.questionManager.deAll") }}</span>
                          </a>&nbsp;&nbsp;<b>{{ $t("orgaExam.questionManager.sQues") }}</b> :
                          <input type="text" v-model="leftSelectCount" class="checkedCount assign_input">
                        </p>

                        <div class="uk-card uk-card-body uk-padding-small uk-width-1-1 uk-margin-remove-top questionTable" >
                          <ul class="uk-list draglist">
                            <template v-for="(item, index) in question2">
                              <li v-if="item.display" :class="{ 'checked': item.checked}" :key="index">
                                <a href="javascript:void(0);" @click="selectQuestion(item, 'left')">
                                  <span>[{{ index + 1 }}]</span>
                                  <span v-if="item.passage == 1"
                                        style="position: absolute;right: 36px;">
                                      Group ID: <span class="uk-text-danger">{{ item.mainId }}</span></span>
                                  <span class="uk-text-right uk-margin-small-left uk-margin-right"
                                        v-if="item.controlNo != '' && item.controlNo != null">[{{ item.controlNo }}]</span>
                                  <span v-html="shortQuestion(item.question)"></span>
                                </a>
                              </li>
                            </template>
                          </ul>
                        </div>
                      </div>
                      <div class="uk-width-1-6@m uk-width-auto@m uk-padding-small uk-flex uk-flex-middle">
                        <ul class="uk-list">
                          <li>
                            <button type="button" @click="insertQuestion"
                                    class="uk-button uk-button-default uk-padding-remove">
                              <span uk-icon="icon: chevron-right; ratio:2.5"></span>
                            </button>
                          </li>
                          <li>
                            <button type="button" class="uk-button uk-button-default uk-padding-remove"
                                    @click="deleteQuestion">
                              <span uk-icon="icon: chevron-left; ratio:2.5"></span>
                            </button>
                          </li>

                        </ul>
                      </div>
                      <div
                          class="uk-width-expand@m uk-width-1-1 uk-margin-remove-left uk-margin-top uk-padding-remove uk-position-relative"
                      >
                        <p class="modify_sbtn" align="left">
                          <a href="javascript:void(0);" @click="selectAll('right', true)"><span
                              class="msbtn2">{{ $t("orgaExam.questionManager.sAll") }}</span></a>
                          <a href="javascript:void(0);" @click="selectAll('right', false)"><span
                              class="msbtn2">{{ $t("orgaExam.questionManager.deAll") }}
                                </span></a>&nbsp;&nbsp;<b>{{ $t("orgaExam.questionManager.aQues") }}</b> : <input
                            type="text" class="checkedCount assign_input"
                            v-model="rightSelectCount"
                            readonly>
                        </p>

                        <div
                            class="uk-card uk-card-body uk-padding-small uk-width-1-1 uk-margin-remove-top questionTable">
                          <ul class="uk-list draglist">
                            <template v-for="(item, index) in types[activeOption].questions">
                              <li v-if="item.display" :class="{ 'checked': item.checked}" :key="index">
                                <a @click="selectQuestion(item, 'right')">
                                  <span>[{{ index + 1 }}]</span><span v-if="item.passage == 1"
                                                                      style="position: absolute;right: 36px;">
                                      Group ID: <span class="uk-text-danger">{{ item.mainId }}</span></span>
                                  <span class="uk-text-right uk-margin-small-left uk-margin-right"
                                        v-if="item.controlNo != '' && item.controlNo != null">[{{ item.controlNo }}]</span>
                                  <span v-html="shortQuestion(item.question)"></span>
                                </a>
                              </li>
                            </template>
                          </ul>
                        </div>

                        <ul class="uk-display-inline-block upordown">
                          <li>
                            <button type="button" @click="sortChange('up')"
                                    class="uk-button uk-button-default uk-padding-remove moveAdd">
                              <span uk-icon="icon: chevron-up; ratio:2.5"></span>
                            </button>
                          </li>
                          <li>
                            <button type="button" @click="sortChange('down')"
                                    class="uk-button uk-button-default uk-padding-remove moveAdd">
                              <span uk-icon="icon: chevron-down; ratio:2.5"></span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
              </li>
              <li v-show="gType == 'auto'">
                <section class="uk-section uk-padding-remove">
                  <div class="uk-container">
                    <div class="uk-width-1-1@m uk-margin-remove-left uk-margin-top uk-margin-bottom" uk-grid>
                      <div class="uk-width-1-2@m uk-flex uk-flex-left">
                        <label for class="uk-label-primary">{{ $t("orgaExam.questionManager.tQues") }} : </label>
                        <label class="uk-label selectedQuestion "
                               style="height:25px; margin-left: 8px; font-size: 1.1em;"> {{ question2.length }}</label>
                      </div>
                      <div class="uk-width-1-2@m uk-flex uk-flex-right">
                        <button type="button" class="uk-button uk-button-primary" @click="randomMax">MAX</button>
                      </div>
                    </div>
                    <table class="uk-table uk-table-small uk-table-responsive uk-margin-remove-top">
                      <thead>
                      <tr style="border-top: 1px solid black">
                        <th class="uk-text-center">No</th>
                        <th class="uk-text-center">{{ $t("orgaExam.questionManager.quesCateName") }}
                          <!-- <button type="button" class="uk-button uk-button-primary uk-button-small uk-margin-left"
                          @click="newQuesCate">add category</button> -->
                        </th>
                        <th class="uk-text-center">{{ $t("orgaExam.questionManager.total") }}</th>
                        <th class="uk-text-center">{{ $t("orgaExam.questionManager.quesNumber") }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td class="uk-text-center"><h4>1</h4></td>
                        <td>
                          <select class="uk-select uk-form-width-medium uk-form-small"
                                  @change="categoryChange('exam', 'main')" v-model="search.cate1">
                            <option value="">Main...</option>
                            <template v-for="(item, index) in mainCategory.main">
                              <option :value="item.id" :key="index">{{ item.name }}</option>
                            </template>
                          </select>
                          <select
                              @change="categoryChange('exam', 'middle')"
                              class="uk-select uk-form-width-medium uk-form-small"
                              v-model="search.cate2">
                            <option value="">Middle...</option>
                            <template v-for="(item, index) in mainCategory.middle">
                              <option :value="item.id" :key="index">{{ item.name }}</option>
                            </template>
                          </select>
                          <select @change="categoryChange('exam', 'low')"
                                  class="uk-select uk-form-width-small uk-form-small"
                                  v-model="search.cate3">
                            <option value="">Low...</option>
                            <template v-for="(item, index) in mainCategory.low">
                              <option :value="item.id" :key="index">{{ item.name }}</option>
                            </template>
                          </select>
                        </td>
                        <td class="uk-text-center">
                          <h4>000</h4>
                        </td>
                        <td class="uk-text-center">
                          <h4>
                            <button type="button" class="uk-padding-remove uk-text-danger"
                                    v-on:click="calculateCount('minusTen')"

                                    uk-icon="icon:  chevron-double-left; ratio:2"></button>
                            <button type="button" class="uk-padding-remove" v-on:click="calculateCount('minusOne')"
                                    uk-icon="icon:  chevron-left; ratio:1.5"></button>
                            {{ count }}
                            <button type="button" class="uk-padding-remove" v-on:click="calculateCount('plusOne')"
                                    uk-icon="icon:  chevron-right; ratio:1.5"></button>
                            <button type="button" class="uk-padding-remove uk-text-danger"
                                    v-on:click="calculateCount('plusTen')"

                                    uk-icon="icon:  chevron-double-right; ratio:2"></button>
                          </h4>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </section>

              </li>
            </ul>
          </li>
        </template>
      </ul>

      <div class="uk-width-1-1 uk-inline uk-margin-remove-left uk-text-right uk-margin-top" uk-grid>
        <button type="button" @click="generate()" :to="{name: ''}"
                class="qbtn_d uk-button uk-button-primary">
          <span v-if="useFlag == 'Y'">{{ $t("orgaExam.questionManager.comp") }}</span>
          <span v-else>{{ $t("orgaExam.questionManager.gene") }}</span>
        </button>

        <router-link :to="{name: 'OrganizationExamList'}"
                     class="qbtn_d uk-button uk-button-secondary">
          {{ $t("orgaExam.questionManager.back") }}
        </router-link>
      </div>
    </section>
    <page-footer/>
  </div>
</template>
<script>
import QuestionService from "../../services/QuestionService";
import ExamService from "../../services/ExamService";
import CategoryDataService from "../../services/CategoryDataService";
import {mapGetters} from "vuex";
import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

const PageFooter = () => import("../Organizcmp/OrganizFooter.vue")

export default {
  name: "app",
  components: {
    PageHeader,
    PageFooter,
    vSelect
  },
  computed: {
    ...mapGetters(["isLoggedIn", "currentUser"])

  },
  data() {
    return {
      count: 0,
      id: 1,
      randomNumber: '',
      examId: 0,
      useFlag: 'N',
      search: {
        cate1: "",
        cate2: "",
        cate3: "",
        keyword: "",
        point: "",
        qtype: "",
        qlevel: "",
        examDate: "",
      },
      questions: [],
      question2: [],
      leftSelectCount: 0,
      rightSelectCount: 0,
      mainCategory: {
        main: [],
        middle: [],
        low: []
      },
      examDetail: {},
      activeOption: 0,
      gType: 'manual',
      types: [
        {
          type: "A",
          questions: []
        }
      ],
      typelib: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K",
        "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W",
        "X", "Y", "Z"],
      cateQuestionCount: 0,
    };
  },
  watch: {
    // $route(to, from) {
    //   if (from.params.examId !== to.params.examId) {
    //     this.examId = to.params.examId
    //     this.getExamPutedQuestionss()
    //   }
    // }
  },
  created() {
    this.examId = this.$route.params.examId
    if (this.examId == 0) {
      window.location.href = "/en/organization/Organization/ExamList"
      return;
    }
    this.getExamPutedQuestionss()
    this.examDetails()
  },
  mounted() {
    this.loadCates("main", 1, 0);
  },
  methods: {
    async generate() {
      if (this.types.length > 0) {
        for (let i = 0; i < this.types.length; i++) {
          if (this.types[i].questions.length < 0) {
            //this.types[0].questions.length
            // alert("It should be equal to the number of items of type A.")
            alert("you should add more than one question in variant. Please check your variants")
            return
          }
        }
      } else {
        alert("type not found")
        return
      }
      try {
        const response = await ExamService.generateExam({examId: this.examId});
        if (response.data.status == 200) {
          this.useFlag = 'Y'
          alert(response.data.message)
          window.location.href = "/en/organization/Organization/ExamList"
        } else {
          alert(response.data.message)
        }
      } catch (error) {
        console.log(error);
      }
    },
    async sortChange(type) {

      if (this.rightSelectCount > 1 || this.rightSelectCount == 0) {
        alert("select one")
        return
      }

      let oldIndex = 0
      for (let i = 0; i < this.types[this.activeOption].questions.length; i++) {
        if (this.types[this.activeOption].questions[i].checked) {
          oldIndex = i
        }
      }
      let newIndex = 0
      if (type == 'up') {
        newIndex = oldIndex - 1
      } else {
        newIndex = oldIndex + 1
      }
      try {
        let sdata = {
          qId1: this.types[this.activeOption].questions[newIndex].examQId,
          qId2: this.types[this.activeOption].questions[oldIndex].examQId
        }
        const response = await ExamService.changeOrder(sdata);
        if (response.data.status == 200) {
          this.types[this.activeOption].questions.splice(newIndex, 0, this.types[this.activeOption].questions.splice(oldIndex, 1)[0])
        } else {
          alert(response.data.message)
        }

      } catch (error) {
        console.log(error);
      }
    },
    calculateCount(item) {
      if (item == 'minusOne') {
        if (this.count < 1) {
          this.count == 0
        } else {
          this.count -= 1
        }
      }
      if (item == 'minusTen') {
        if (this.count < 1) {
          this.count == 0
        } else {
          this.count -= 10
        }
      }
      if (item == 'plusOne') {
        this.count += 1
      }
      if (item == 'plusTen') {
        this.count += 10
      }
    },
    async examDetails() {
      try {
        this.examDetail = {}
        const response = await ExamService.getExamDetails(this.examId, {
          grpId: 0,
          orgId: this.currentUser.organizs[0].organizId
        });
        if (response.data.status == 200) {
          this.examDetail = response.data.result.examDetail
        }
      } catch (error) {
        console.log(error)
      }
    },
    insertQuestion() {
      // alert(this.types[this.activeOption].type)

      for (let i = 0; i < this.question2.length; i++) {
        let item = this.question2[i];

        if (item.checked) {
          let insitem = {
            checked: false,
            question: item.question,
            id: item.id,
            examQId: -1,
            display: false,
            controlNo: item.controlNo,
            passage: item.passage,
            mainId: item.mainId
          }
          // this.selectQuestion(item, "left")
          this.types[this.activeOption].questions.push(insitem)
        }
      }
      this.putExamQuestions()
    },
    async deleteQuestion() {
      let qIds = []
      for (let i = 0; i < this.types[this.activeOption].questions.length; i++) {
        let item = this.types[this.activeOption].questions[i];

        if (item.checked) {
          // this.selectQuestion(item, "right")
          qIds.push(item.examQId)
          // this.types[this.activeOption].questions.splice(i, 1)
        }
      }
      if (qIds.length == 0) {
        alert("Please check question")
        return;
      }

      try {
        let sdata = {
          examId: this.examId,
          type: this.types[this.activeOption].type,
          qIds: qIds,
        }
        const response = await ExamService.deleteExamQuestions(sdata);

        if (response.data.status == 200) {
          alert(response.data.message)
          this.getExamPutedQuestionss()
        } else {
          alert(response.data.message)
        }

      } catch (error) {
        console.log(error);
      }

    },
    randomMax() {
      this.count = Math.floor(Math.random() * (10 - 1 + 1)) + 1
    },
    selectAll(box, sord) {
      if (box == 'left') {

        for (let i = 0; i < this.question2.length; i++) {
          let item = this.question2[i]
          item.checked = sord
        }
        if (sord) {
          this.leftSelectCount = this.question2.length
        } else {
          this.leftSelectCount = 0
        }

      }
      if (box == "right") {
        for (let i = 0; i < this.types[this.activeOption].questions.length; i++) {
          let item = this.types[this.activeOption].questions[i]
          item.checked = sord
        }
        if (sord) {
          this.rightSelectCount = this.types[this.activeOption].questions.length
        } else {
          this.rightSelectCount = 0
        }

      }
    },

    selectQuestion(item, LorR) {

      if (LorR == 'left') {
        if (item.passage == 1) {
          console.log("this.question2 ")
          console.log(this.question2)
          for (let i = 0; i < this.question2.length; i++) {
            if (item.mainId == this.question2[i].mainId
                && this.question2[i].passage == 1) {
              this.question2[i].checked = !this.question2[i].checked
              if (this.question2[i].checked)
                this.leftSelectCount++
              else
                this.leftSelectCount--;
            }
          }
        } else {
          item.checked = !item.checked
          if (item.checked)
            this.leftSelectCount++
          else
            this.leftSelectCount--;
        }
      } else {
        if (item.passage == 1) {
          console.log("this.questions ")
          console.log(this.types[this.activeOption].questions)
          for (let i = 0; i < this.types[this.activeOption].questions.length; i++) {
            if (item.mainId == this.types[this.activeOption].questions[i].mainId
                && this.types[this.activeOption].questions[i].passage == 1) {
              this.types[this.activeOption].questions[i].checked = !this.types[this.activeOption].questions[i].checked

              if (this.types[this.activeOption].questions[i].checked)
                this.rightSelectCount++
              else
                this.rightSelectCount--;
            }
          }
        } else {
          item.checked = !item.checked
          if (item.checked)
            this.rightSelectCount++
          else
            this.rightSelectCount--;
        }
      }
    },
    shortQuestion(question) {
      if (question && question.length > 50) {
        return question.substring(0, 50) + '...'
      } else {
        return question
      }
    },

    generateType(type) {
      this.gType = type
    },
    selectType(index) {
      this.activeOption = index

      this.rightSelectCount = 0
      for (let i = 0; i < this.types[this.activeOption].questions.length; i++) {
        if (this.types[this.activeOption].questions[i].checked) {
          this.rightSelectCount++
        }
      }

      this.boxleftDisplayItems()
      this.leftSelectCount = 0
      for (let i = 0; i < this.question2.length; i++) {
        if (this.question2[i].checked && this.question2[i].display) {
          this.leftSelectCount++
        }
      }
    },

    addType() {
      let type = {
        type: this.typelib[this.types.length],
        questions: []
      }
      this.types.push(type)
      this.selectType(this.types.length - 1)
    },

    async removeType() {
      try {

        if (this.types[this.activeOption].type == 'A') {
          alert("Must be at least 1")
          return
        }
        let sdata = {
          examId: this.examId,
          option: this.types[this.types.length - 1].type
        }

        const response = await ExamService.deleteExamOption(sdata);

        if (response.data.status == 200) {
          alert(response.data.message)
          this.types.splice(this.types.length - 1, 1)
          this.selectType(this.types.length - 1)
          this.getExamPutedQuestionss()
        } else {
          alert(response.data.message)
        }

      } catch (error) {
        console.log(error);
      }
    },
    defaultSetting() {
      window.location.reload(true);
    },
    async questionData() {
      try {
        this.question2 = []

        const response = await QuestionService.getQuestionsData({
          organizId: this.currentUser.organizs[0].organizId,
          // startDate: this.search.startDate,
          // endDate: this.search.endDate,
          examId: this.examId,
          keyword: this.search.keyword,
          qtype: this.search.qtype,
          qlevel: this.search.qlevel,
          cate1: this.search.cate1,
          cate2: this.search.cate2,
          cate3: this.search.cate3,
        });

        for (let i = 0; i < response.data.length; i++) {
          let item = {
            checked: false,
            question: response.data[i].question,
            id: response.data[i].id,
            display: true,
            controlNo: response.data[i].controlNo,
            passage: response.data[i].passage,
            mainId: response.data[i].mainId
          }
          this.question2.push(item)
        }
        this.boxleftDisplayItems()
      } catch (error) {
        console.log(error);
      }
    },
    boxleftDisplayItems() {
      for (let i = 0; i < this.question2.length; i++) {

        let itemq = this.question2[i]

        itemq.checked = false
        if (this.isExistQId(itemq.id)) {
          itemq.display = false
        } else {
          itemq.display = true
        }
      }
    },
    async getExamPutedQuestionss() {
      try {
        const response = await ExamService.getExamPutedQuestions({
          examId: this.examId,
        });

        if (response.data.status == 200) {

          let questions = response.data.result.putedQuestions
          this.useFlag = response.data.result.useFlag
          const m = new Map()
          for (let i = 0; i < questions.length; i++) {
            m.set(questions[i].option, questions[i].option)
          }

          let lastVariant = 'B'
          for (const v of m.values()) {
            lastVariant = v
          }

          this.types = []
          //dund ni ymar nengen variant aldagdsan bol nohoh
          for (let i = 0; i < this.typelib.length; i++) {
            if (lastVariant == this.typelib[i]) {
              break
            }
            let inittype = {
              type: this.typelib[i],
              questions: []
            }
            this.types.push(inittype)
          }
          for (let i = 0; i < questions.length; i++) {
            let item = questions[i]
            let insitem = {
              checked: false,
              question: item.question,
              id: item.pQid,
              examQId: item.qId,
              display: true,
              controlNo: item.controlNo,
              passage: item.passage,
              mainId: item.mainId
            }
            var existIndex = this.isExist(item.option)

            if (existIndex > -1) {
              this.types[existIndex].questions.push(insitem)
            } else {
              let typee = {
                questions: []
              }
              typee.type = item.option
              typee.questions.push(insitem)

              console.log("typee questions")
              // console.log(this.types[existIndex].questions)
              this.types.push(typee)
            }
          }
          //ene ni hamgin svvlin variantin bvh asuult delete all bolson bol svvliin variantiig heweer vldeeh
          for (let i = 0; i < this.activeOption - (this.types.length - 1); i++) {
            this.addType()
          }
          // call all questions
          this.questionData()
        }
      } catch (error) {
        console.log(error);
      }
    },
    isExist: function (option) {
      for (let i = 0; i < this.types.length; i++) {
        if (this.types[i].type === option)
          return i
      }
      return -1
    },
    isExistQId: function (qId) {
      for (let i = 0; i < this.types[this.activeOption].questions.length; i++) {
        if (this.types[this.activeOption].questions[i].id == qId)
          return true
      }
      return false
    },
    async putExamQuestions() {
      try {
        let data = this.types[this.activeOption]

        if (data.questions.length == 0) {
          alert("select questions")
          return;
        }
        data.examId = this.examId

        const response = await ExamService.putExamQuestions(data);
        if (response.data.status == 200) {
          alert(response.data.message)
          this.getExamPutedQuestionss()
        } else {
          alert(response.data.message)
        }

      } catch (error) {
        console.log(error);
      }
    },
    categoryChange(type, catelevel) {
      if (type == "exam") {
        type = "main";
      }

      if (catelevel == "main") {
        let parentId = 0;
        if (type == "main") parentId = this.search.cate1;
        else parentId = this.search.scate1;
        this.loadCates(type, 2, parentId);
      }
      if (catelevel == "middle") {
        let parentId = 0;
        if (type == "main") parentId = this.search.cate2;
        else parentId = this.search.scate2;
        this.loadCates(type, 3, parentId);
      }
    }
    ,
    async loadCates(type, cateStep, parentId) {
      try {
        const response = await CategoryDataService.getCategoriesStep({
          type: type,
          cateStep: cateStep,
          parentId: parentId
        });

        if (response.data.status === 200) {
          if (type == "main") {
            //Category
            if (cateStep == 1) {
              this.mainCategory.main = response.data.result.cates;
            }
            if (cateStep == 2) {
              this.mainCategory.middle = response.data.result.cates;
            }
            if (cateStep == 3) {
              this.mainCategory.low = response.data.result.cates;
            }
          }
        } else {
          alert("ERROR: " + response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
    ,

  }
}
;
</script>
<style scoped>
.qbtn_d {
  border: 1px solid #fff;
  color: #fff;
  font-weight: 500;
  min-width: 30px;
  text-align: center;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.uk-card.uk-card-body {
  border: 1px dotted #dee2fe;
}


ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
  padding: 0;
}

.ultypes li a {
  display: block;
  background: #f5f0f0;
  border: 1px solid #0e4085;
  color: #0e4085;
  border-radius: 0px;
  margin-right: 5px;
  margin-bottom: 10px;
  width: 76px;
  height: 37px;

}

.ultypes li.checked a {
  background: #0e4085;
  color: #fff;
}

.draglist li a {
  text-decoration: none;
  color: #000;
  display: block;
  border: 1px solid #ccc;
  padding: 5px;
}

.draglist .checked a {
  background: #f4f4f4;
  border: 1px solid #000000;
}


.modify_sbtn .msbtn2 {
  border: 1px solid #aaa;
  background-color: #fafafa;
  color: #333;
  padding: 5px 10px;
  border-radius: .2em;
}

.assign_input {
  width: 38px;
  height: 20px;
  text-align: center;
  font-weight: bold;
}

.questionTable {
  height: 600px;
  overflow: scroll;
}

.add-type {
  border: 1px solid #0e4085;
  border-radius: 0px;
  color: #0e4085;
  width: 114px;
  height: 37px;
  line-height: 19px;
}

.remove-type {
  border: 1px solid #f0506e;
  border-radius: 0px;
  color: #f0506e;
  width: 114px;
  height: 37px;
  background: #f8f8f8;
  line-height: 19px;
}

.search-table tr td {
  padding: 5px;
}

.generateTab li a {
  background: #eeeeee;
  border: 1px solid #cccccc;
  color: #666;
}

.generateTab .uk-active a {
  border: 1px solid #0e4085;
  background: #0e4085;
  color: #ffffdd;
}

.upordown {
  position: absolute;
  right: 0;
  margin-right: -55px;
  top: 39%;
  height: 100%;
}

.upordown li {
  margin-bottom: 5px;
}

tr .tit {
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  text-align: left;
}

td {
  text-align: left !important;
}

.vs__clear {
  display: none;
}
.vs__dropdown-menu{
  width: 80% !important;
}
</style>
